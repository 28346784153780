<template>
  <el-empty description="404页面"></el-empty>
</template>

<script>
import { ElEmpty } from "element-plus";
export default {
  name: "Error",
  components: {
    [ElEmpty.name]: ElEmpty,
  },
};
</script>

<style>
</style>